<template>
  <div
    v-if="showing"
    class="bk-container"
  >
    <lottie-animation
      class="bk-bike"
      :animation-data="require('@src/assets/animations/bk-bike.json')"
      :loop="false"
      :auto-play="true"
      @complete="animationFinished"
    />
    <lottie-animation
      class="bk-logo"
      :animation-data="require('@src/assets/animations/bk-logo.json')"
      :loop="false"
      :auto-play="true"
    />
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'

const COOLDOWN = 60000

export default {
  name: 'BurgerKingAnimation',
  components: {
    LottieAnimation
  },
  data () {
    return {
      showing: false,
      lastShown: null
    }
  },
  props: {
  },
  mounted () {
  },
  methods: {
    animationFinished () {
      this.showing = false
    },
    play () {
      if (!this.lastShown || new Date() - this.lastShown >= COOLDOWN) {
        this.showing = true
        this.lastShown = new Date()
      }
    }
  }
}
</script>

<style lang="scss">
  .bk-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  .bk-bike {
    bottom: 175px;
    left: 0px;
    width: 100%;
  }

  .bk-logo {
    width: 380px;
    bottom: 34px;
    left: 0px;
  }
</style>
