<template>
  <transition name="fade" :duration="500">
    <div
      v-if="eventData.id"
      class="donation-event"
      :class="['text-' + eventData.view_pattern.text, { 'only-text': !eventData.image }]"
      :style="{ '--text-color': eventData.header_text_color, '--message-text-color': eventData.header_message_text_color }"
    >
      <div class="row">
        <img
          v-if="eventData.image"
          class="image"
          :src="eventData.image.url"
        />
        <img
          v-else
          class="image"
          src="../../assets/papara.svg"
        />
        <div class="text">{{ textContent }}</div>
      </div>
      <div
        v-if="eventData.header_show_message && eventData.donation.message"
        class="big-message"
        :class="{
          'big': eventData.donation.message.length <= 144,
          'medium': eventData.donation.message.length >= 145 && eventData.donation.message.length <= 185,
          'small': eventData.donation.message.length > 185
        }"
      >{{ eventData.donation.message }}</div>
    </div>
  </transition>
</template>

<script>
import layerMixin from '../../mixins/layer'

export default {
  name: 'DonationEventPage',
  mixins: [layerMixin],
  components: {
  },
  data () {
    return {
      pusher: null,
      channels: {},
      eventData: {},
      currentAudio: null,
      hideTimeout: null
    }
  },
  computed: {
    textContent () {
      let text = this.eventData.header_pattern
      text = text.replace('{username}', this.eventData.donation.username)
      text = text.replace('{amount}', this.eventData.donation.amount)
      // text = text.replace('{message}', this.eventData.donation.message)
      return text
    }
  },
  mounted () {
    this.initPusher()

    this.enableWindowSizeTracking('donation-event')

    this.initEventsTracking()
  },
  methods: {
    initEventsTracking () {
      this.subscribeChannel('donations.' + this.userLink)
      this.channels['donations.' + this.userLink]
        .bind('App\\Events\\Streamers\\Donations\\DonationEventShown', (e) => {
          this.eventData = e

          if (this.hideTimeout) {
            clearTimeout(this.hideTimeout)
          }

          if (this.currentAudio) {
            this.currentAudio.pause()
          }

          if (this.eventData.sound?.url) {
            this.currentAudio = new Audio(this.eventData.sound.url)
            if (this.currentAudio) {
              this.currentAudio.volume = this.eventData.sound_volume ? this.eventData.sound_volume / 100 : 1
              this.currentAudio.play()
            }
          }

          this.hideTimeout = setTimeout(() => {
            if (this.currentAudio) {
              this.currentAudio.pause()
            }
            this.eventData = {}
          }, this.eventData.duration * 1000)
        })
    }
  }
}
</script>

<style lang="scss">
  @import '../../assets/fonts.scss';

  .donation-event {
    font-size: (100*100/484)+vw;

    .row {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 4.84em;
      height: 2.14em;
    }

    .image {
      object-fit: contain;
      width: 2.48em;
      height: 1.68em;
      order: 2;
    }

    .text {
      font-family: 'GothamPro';
      font-weight: 500;
      font-size: 0.24em;
      line-height: (0.3/0.24)+em;
      color: var(--text-color);
      // width: (2.34/0.24)+em;
      padding: 0px 0.2em;
      height: (0.34/0.24)+em;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: (0.04/0.24)+em;
    }

    .big-message {
      font-family: 'GothamPro';
      color: var(--message-text-color);
      margin-left: auto;
      margin-right: auto;

      &.big {
        font-size: 0.2em;
        line-height: (0.3/0.2)+em;
        text-shadow: 0px 0px (0.04/0.2)+em rgba(0, 0, 0, 0.25);
        width: (3.2/0.2)+em;
        margin-top: (0.1/0.2)+em;
      }

      &.medium {
        font-size: 0.18em;
        line-height: (0.24/0.18)+em;
        text-shadow: 0px 0px (0.04/0.18)+em rgba(0, 0, 0, 0.25);
        width: (3.2/0.18)+em;
        margin-top: (0.1/0.18)+em;
      }
      
      &.small {
        font-size: 0.16em;
        line-height: (0.24/0.16)+em;
        text-shadow: 0px 0px (0.04/0.16)+em rgba(0, 0, 0, 0.25);
        width: (3.2/0.16)+em;
        margin-top: (0.1/0.16)+em;
      }
    }

    &.text-top, &.text-bottom {
      .row {
        flex-direction: column;
      }
    }

    &.text-left, &.text-right {
      .row {
        flex-direction: row;
      }

      .image {
        position: absolute;
        z-index: 1;
      }

      .text {
        position: relative;
        z-index: 10;
      }
    }

    &.text-top {
      .text {
        order: 1;
        margin-bottom: (0.13/0.24)+em;
      }
    }

    &.text-bottom {
      .text {
        order: 3;
        margin-top: (0.13/0.24)+em;
      }
    }

    &.text-left {
      .text {
        order: 1;
        // margin-right: (0.1/0.24)+em;
        margin-left: 0px;
        margin-right: auto;
      }

      .image {
        right: 0px;
      }
    }

    &.text-right {
      .text {
        order: 3;
        // margin-left: (0.1/0.24)+em;
        margin-left: auto;
        margin-right: 0px;
      }

      .image {
        left: 0px;
      }
    }

    &.text-overlap {
      .row {
        position: relative;
      }

      .image {
        position: absolute;
        left: 50%;
        margin-left: -1.24em;
        top: 50%;
        margin-top: -0.84em;
        z-index: 1;
      }

      .text {
        z-index: 5;
      }
    }

    &.only-text {
      .text {
        margin: 0px!important;
      }
    }
  }

</style>