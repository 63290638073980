<template>
  <div class="one-thid-banner-box" :style="{ '--one-third-duration': duration + 's' }">
    <div id="one-third-animation-layer"></div>
    <div
      id="one-third-progress-bar"
      :class="'at-' + direction"
    ></div>
    <div
      id="one-third-banner-media"
      ref="mediaElement"
      :class="['at-' + direction, imageShown ? 'appear' : 'disappear']"
    >
      <img
        v-if="!isVideo"
        :src="contentUrl"
        rel="preload"
        class="media"
      />
      <video
        v-else
        class="media"
        :src="hasSrcset ? null : contentUrl"
        preload="auto"
        :autoplay="true"
        :loop="true"
      >
        <template v-if="hasSrcset">
          <source
            v-for="(url, type) in contentSrcset"
            :key="type"
            :src="url"
            :type="type"
          />
        </template>
      </video>
      <qr-container
        v-if="qrPosition"
        :link="qrLink"
        :size="qrSize"
        class="banner-qr"
        :class="qrPosition"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { initConfetti } from '../confetti'
import QrContainer from '../components/QrContainer'

export default {
  name: 'OneThirdBannerBox',
  components: {
    QrContainer
  },
  data () {
    return {
      imageShown: false,
      bannerSize: null
    }
  },
  props: {
    contentUrl: {
      type: String,
      required: true
    },
    contentSrcset: {
      type: Object,
      default: null
    },
    endUrl: {
      type: String,
      required: true
    },
    direction: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      required: true
    },
    qrPosition: {
      type: String,
      default: null
    },
    qrLink: {
      type: String,
      default: null
    },
    isVideo: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    try {
      this.imageShown = true

      setTimeout(() => {
        let confettiData = initConfetti('one-third-animation-layer')

        if (this.direction === 'top' || this.direction === 'bottom') {
          let y = this.direction === 'top' ? this.$refs.mediaElement.offsetHeight : document.body.offsetHeight - this.$refs.mediaElement.offsetHeight
          confettiData.makeBang({ x: 0, y: y }, { x: document.body.offsetWidth, y: y }, 10)
        } else {
          let x = this.direction === 'left' ? this.$refs.mediaElement.offsetWidth : document.body.offsetWidth - this.$refs.mediaElement.offsetWidth
          confettiData.makeBang({ x: x, y: 0 }, { x: x, y: document.body.offsetHeight }, 10)
        }

        this.bannerSize = (this.direction === 'top' || this.direction === 'bottom') ? this.$refs.mediaElement.offsetHeight : this.$refs.mediaElement.offsetWidth
      }, 2000)

      setTimeout(() => {
        if (this.endUrl) {
          axios.get(this.endUrl)
        }

        this.imageShown = false

        setTimeout(() => {
          this.$emit('finished')
        }, 1000)
      }, this.duration * 1000 + 1000)
    } catch (e) {
      console.error('finishing because of error', e)
      this.$emit('finished')
    }
  },
  computed: {
    qrSize () {
      let divider = (this.direction === 'top' || this.direction === 'bottom') ? 2 : 3
      if (this.bannerSize && (this.bannerSize - 50) / divider < 115) {
        return Math.floor((this.bannerSize - 50) / divider)
      } {
        return 115
      }
    },
    hasSrcset () {
      return this.contentSrcset && Object.keys(this.contentSrcset).length > 0
    }
  }
}
</script>

<style lang="scss">
  #one-third-banner-media {
    position: fixed;
    z-index: 5;
    animation-duration: 1s;
    animation-fill-mode: forwards;

    &.appear {
      animation-delay: 1s;
      animation-timing-function: cubic-bezier(1, 0, 1, 1);
    }

    &.disappear {
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
    }
    
    &.at-top {
      width: 100%;
      top: 0px;
      left: 0px;
      transform: translateY(calc(-100% - 7px));
    }

    &.at-bottom {
      width: 100%;
      bottom: 0px;
      left: 0px;
      transform: translateY(calc(100% + 7px));
    }

    &.at-top .media, &.at-bottom .media {
      width: 100%;
    }

    &.at-left {
      top: 0px;
      left: 0px;
      height: calc(100% - 7px);
      transform: translateX(calc(-100%));
    }

    &.at-right {
      top: 0px;
      right: 0px;
      height: calc(100% - 7px);
      transform: translateX(calc(100%));
    }

    &.at-right .media, &.at-left .media {
      height: 100%;
    }

    &.disappear {
      &.at-top {
        animation-name: one-third-disappear-from-top;
      }
      &.at-bottom {
        animation-name: one-third-disappear-from-bottom;
      }
      &.at-left {
        animation-name: one-third-disappear-from-left;
      }
      &.at-right {
        animation-name: one-third-disappear-from-right;
      }
    }

    &.appear {
      &.at-top {
        animation-name: one-third-appear-from-top;
      }
      &.at-bottom {
        animation-name: one-third-appear-from-bottom;
      }
      &.at-left {
        animation-name: one-third-appear-from-left;
      }
      &.at-right {
        animation-name: one-third-appear-from-right;
      }
    }
  }

  @keyframes one-third-disappear-from-top {
    from { transform: translateY(0%) }
    to { transform: translateY(calc(-100% - 7px)) }
  }
  @keyframes one-third-appear-from-top {
    from { transform: translateY(calc(-100% - 7px)) }
    to { transform: translateY(0%) }
  }
  @keyframes one-third-disappear-from-bottom {
    from { transform: translateY(0%) }
    to { transform: translateY(calc(100% + 7px)) }
  }
  @keyframes one-third-appear-from-bottom {
    from { transform: translateY(calc(100% + 7px)) }
    to { transform: translateY(0%) }
  }
  @keyframes one-third-disappear-from-left {
    from { transform: translateX(0%) }
    to { transform: translateX(calc(-100%)) }
  }
  @keyframes one-third-appear-from-left {
    from { transform: translateX(calc(-100%)) }
    to { transform: translateX(0%) }
  }
  @keyframes one-third-disappear-from-right {
    from { transform: translateX(0%) }
    to { transform: translateX(calc(100%)) }
  }
  @keyframes one-third-appear-from-right {
    from { transform: translateX(calc(100%)) }
    to { transform: translateX(0%) }
  }

  #one-third-animation-layer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  #one-third-progress-bar {
    position: fixed;
    height: 7px;
    width: 100%;
    z-index: 7;
    left: 0px;
    /* opacity: 0; */
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    background-color: #192140;
    animation-timing-function: ease;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0px;
      left: 0px;
      top: 0px;
      height: 100%;
      animation-duration: var(--one-third-duration);
      animation-delay: 2s;
      animation-fill-mode: forwards;
      background-color: #4870ff;
      animation-timing-function: linear;
      animation-name: width-grow;
    }

    &.at-top {
      top: 0px;
    }

    &.at-bottom {
      bottom: 0px;
    }

    &.at-left {
      bottom: 0px;
    }

    &.at-right {
      bottom: 0px;
    }
  }

  @keyframes width-grow {
    from { width: 0%; }
    to { width: 100%; }
  }

  .banner-qr {
    position: absolute;

    &.rt {
      top: 15px;
      right: 15px;
    }

    &.rb {
      bottom: 15px;
      right: 15px;
    }

    &.lb {
      bottom: 15px;
      left: 15px;
    }

    &.lb-special {
      bottom: 15px;
      left: calc(15px + 14%);
    }

    &.lt {
      top: 15px;
      left: 15px;
    }
  }
</style>
