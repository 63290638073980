<template>
  <div class="full-screen-banner-box">
    <img
      ref="imageElement"
      :src="contentUrl"
      rel="preload"
    />
    <div class="countdown">{{ secondsLeft }}</div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FullScreenBannerBox',
  data () {
    return {
      secondsLeft: this.duration,
      countdownInterval: null
    }
  },
  props: {
    contentUrl: {
      type: String,
      required: true
    },
    endUrl: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      required: true
    }
  },
  mounted () {
    try {
      this.countdownInterval = setInterval(() => {
        if (this.secondsLeft > 0) {
          this.secondsLeft--
        }
      }, 1000)

      setTimeout(() => {
        if (this.endUrl) {
          axios.get(this.endUrl)
        }

        this.$emit('finished')
      }, this.duration * 1000)

    } catch (e) {
      console.error('finishing because of error', e)
      this.$emit('finished')
    }
  }
}
</script>

<style lang="scss">
  .full-screen-banner-box {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    padding: 12px;

    img {
      width: 100%;
      max-height: 100%;
      padding: 0px;
      position: relative;
      z-index: 5;
      object-fit: contain;
    }
  }

  .countdown {
    background-color: rgb(182, 118, 230);
    border-radius: 18px;
    color: #fff;
    font-size: 1em;
    font-weight: 300;
    font-family: monospace;
    height: 32px;
    line-height: 32px;
    padding: 0px 7px;
    position: absolute;
    min-width: 32px;
    right: 10px;
    top: 10px;
    text-align: center;
    z-index: 10;
  }
</style>
