import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'

Vue.config.productionTip = false

if (process.env.VUE_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    Vue,
    dsn: 'https://0a0dac14c1f6410b84aac98d395d0208@sentry.apiq.live/6',
    // integrations: [
    //   new BrowserTracing({
    //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    //     tracingOrigins: ["localhost", "my-site-url.com", /^\//],
    //   }),
    // ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
