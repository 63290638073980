<template>
  <transition name="fade" :duration="500">
    <div v-if="taskData">
      <img
        v-if="taskData.content_url.endsWith('.gif')"
        :src="taskData.content_url"
        style="width: 100vw; height: 100vh; object-fit: contain;"
      />
      <video
        v-else
        ref="videoElement"
        :src="taskData.content_srcset ? null : taskData.content_url"
        preload="auto"
        :autoplay="true"
        :loop="true"
        style="width: 100vw; height: 100vh;"
      >
        <template v-if="taskData.content_srcset">
          <source
            v-for="(url, type) in taskData.content_srcset"
            :key="type"
            :src="url"
            :type="type"
          />
        </template>
      </video>
    </div>
  </transition>
</template>

<script>
import layerMixin from '../mixins/layer'

export default {
  name: 'StaticBannerPage',
  mixins: [layerMixin],
  components: {
  },
  data () {
    return {
      pusher: null,
      channels: {},
      taskData: null
    }
  },
  computed: {
  },
  methods: {
    initTaskLaunch() {
      this.subscribeChannel('task-launch.' + this.userLink)
      // this.channels['task-launch.' + this.userLink]
        .bind('App\\Events\\Tasks\\StaticBannerShown', (e) => {
          this.taskData = e
          console.log('got static launch event', e)

          let tssId = e.tss_id

          setTimeout(() => {
            console.log('after timeout', this.taskData?.tss_id, tssId, this.taskData?.tss_id === tssId)
            if (this.taskData && this.taskData.tss_id === tssId) {
              this.taskData = null
            }
          }, e.duration + 2000)
        })
    }
  },
  mounted () {
    this.initPusher('presence-static-layer-meta')
    this.initTaskLaunch()

    this.enableWindowSizeTracking('static')
  }
}
</script>

<style>
</style>