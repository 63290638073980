import sketch from 'sketch-js'

let sketchContext = {}

sketch.install(sketchContext)

function Particle( x, y, radius ) {
  this.init( x, y, radius )
}

Particle.prototype = {
  init: function( x, y, radius ) {
    this.alive = true

    this.radius = radius || 10
    this.wander = 0.15
    this.theta = sketchContext.random( sketchContext.TWO_PI )
    this.drag = 0.92
    this.color = '#fff'

    this.x = x || 0.0
    this.y = y || 0.0

    this.vx = 0.0
    this.vy = 0.0
  },

  move: function() {
    this.x += this.vx
    this.y += this.vy

    this.vx *= this.drag
    this.vy *= this.drag

    this.theta += sketchContext.random( -0.5, 0.5 ) * this.wander
    this.vx += sketchContext.sin( this.theta ) * 0.1
    this.vy += sketchContext.cos( this.theta ) * 0.1

    this.radius *= 0.96
    this.alive = this.radius > 0.5
  },

  draw: function( ctx ) {
      ctx.beginPath()
      ctx.arc( this.x, this.y, this.radius, 0, sketchContext.TWO_PI )
      ctx.fillStyle = this.color
      ctx.fill()
  }
}

const MAX_PARTICLES = 280
const COLOURS = [ '#69D2E7', '#A7DBD8', '#E0E4CC', '#F38630', '#FA6900', '#FF4E50', '#F9D423' ]

export const initConfetti = (containerId) => {
  let particles = []
  let pool = []

  let confettiCanvas = sketch.create({
    container: document.getElementById(containerId)
  })
  
  confettiCanvas.spawn = function( x, y ) {
    if ( particles.length >= MAX_PARTICLES )
        pool.push( particles.shift() )
  
    let particle = pool.length ? pool.pop() : new Particle()
    particle.init( x, y, sketchContext.random( 5, 40 ) )
  
    particle.wander = sketchContext.random( 0.5, 2.0 )
    particle.color = sketchContext.random( COLOURS )
    particle.drag = sketchContext.random( 0.9, 0.99 )
  
    let theta = sketchContext.random( sketchContext.TWO_PI )
    let force = sketchContext.random( 2, 8 )
  
    particle.vx = sketchContext.sin( theta ) * force
    particle.vy = sketchContext.cos( theta ) * force
  
    particles.push( particle )
  }
  
  confettiCanvas.update = function() {
  
    let i, particle
  
    for ( i = particles.length - 1; i >= 0; i-- ) {
  
        particle = particles[i]
  
        if ( particle.alive ) particle.move()
        else pool.push( particles.splice( i, 1 )[0] )
    }
  }
  
  confettiCanvas.draw = function() {
  
    confettiCanvas.globalCompositeOperation  = 'lighter'
  
    for ( var i = particles.length - 1; i >= 0; i-- ) {
        particles[i].draw( confettiCanvas )
    }
  }

  return {
    makeBang: (from, to, step) => {
      let dx = to.x - from.x
      let dy = to.y - from.y
      // trig may be sketch.sin or sketch.cos, we get max to prevent rounding issues
      let trig = Math.max(dx, dy) / Math.sqrt(dx * dx + dy * dy)
      let axisStep = step * trig

      if (dx > dy) {
        for(let x = from.x; x < to.x; x += axisStep) {
          let y = from.y + dy * x / dx
          confettiCanvas.spawn(x, y)
        }
      } else {
        for(let y = from.y; y < to.y; y += axisStep) {
          let x = from.x + dx * y / dy
          confettiCanvas.spawn(x, y)
        }
      }

      confettiCanvas.update()
    }
  }
}