<template>
  <div
    v-if="goalData.id"
    class="donation-goal"
    :style="{ '--text-color': goalData.second_color, '--bar-color-from': goalData.first_color + 'FF', '--bar-color-to': goalData.first_color + '00' }"
  >
    <div class="top-line">
      <div class="name">{{ goalData.name }}</div>
      <div class="amount">{{ goalData.current_amount_text }} / {{ goalData.amount_text }}</div>
    </div>
    <div class="bar">
      <div class="bar-inner" :style="'width: ' + formattedProgress + ';'"></div>
    </div>
    <img
      :class="{ 'highlighted': logoHighlighted }"
      class="image"
      src="../../assets/papara.svg"
    />
  </div>
</template>

<script>
import axios from 'axios'
import layerMixin from '../../mixins/layer'

export default {
  name: 'DonationGoalPage',
  mixins: [layerMixin],
  components: {
  },
  data () {
    return {
      pusher: null,
      channels: {},
      goalData: {},
      logoHighlighted: false
    }
  },
  computed: {
    formattedProgress () {
      return Math.min(100 * this.goalData.current_amount / this.goalData.amount, 100) + '%'
    }
  },
  mounted () {
    this.initPusher()

    this.enableWindowSizeTracking('donation-goal')

    this.loadInitialData()
    this.initGoalTracking()

    this.highlightLogo()
    setInterval(() => {
      this.highlightLogo()
    }, 10 * 60 * 1000)
  },
  methods: {
    loadInitialData () {
      axios.get(this.backendHost + '/api/web-dashboard/v1/st/donations/layers/goal/' + this.userLink).then((res) => {
        this.goalData = res.data
      })
    },
    initGoalTracking () {
      this.subscribeChannel('donations.' + this.userLink)
      this.channels['donations.' + this.userLink]
        .bind('App\\Events\\Streamers\\Donations\\DonationGoalUpdated', (e) => {
          this.goalData = e
        })
    },
    highlightLogo () {
      this.logoHighlighted = true
      setTimeout(() => {
        this.logoHighlighted = false
      }, 60000)
    }
  }
}
</script>

<style lang="scss">
  @import '../../assets/fonts.scss';

  .donation-goal {
    font-size: (100*100/540)+vw;

    width: 5.4em;
    background-color: #FFFFFF;
    border-radius: 0.16em;
    padding: 0.16em 0.24em;

    .top-line {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name, .amount {
        font-family: 'GothamPro';
        font-size: 0.2em;
        line-height: (0.24 / 0.2)+em;
        font-weight: 500;
        color: var(--text-color);
      }
    }

    .bar {
      height: 0.24em;
      background: #F2F3F7;
      border-radius: 0.08em;
      margin-top: 0.08em;

      &-inner {
        height: 100%;
        background: linear-gradient(270deg, var(--bar-color-from) 0%, var(--bar-color-to) 100%);
        border-radius: 0.08em;
        transition: width 0.7s ease;
      }
    }
    
    .image {
      display: block;
      width: 1.25em;
      margin-left: auto;
      margin-right: 0px;
      margin-top: 0.12em;
      opacity: 0.2;
      transition: opacity 0.3s ease;
      
      &.highlighted {
        opacity: 1;
      }
    }
  }

</style>