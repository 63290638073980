<template>
  <img src="../assets/404.jpg" />
</template>

<script>
export default {
  name: 'NotFoundPage',
  data () {
    return {
      // 04a9eb
    }
  },
  mounted () {
    document.body.classList.add('page404')
  },
  destroyed () {
    document.body.classList.remove('page404')
  }
}
</script>

<style scoped>
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>