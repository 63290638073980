<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
    }
  },
  created () {
    window.OnSceneLoad = window.OnSceneLoad || function () {
    }

    window.SetEvent = window.SetEvent || function () {
    }
  }
}
</script>

<style>
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    background: transparent;
    height: 100%;
  }
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  body.page404 {
    background-color: #04a9eb;
  }

  /* img {
    max-width: 100%;
    display: block;
  } */
  
  body {
    overflow: hidden;
  }

  body.dark-layer {
    background-color: rgba(0, 0, 0, 0.8);
  }

  #app {
    width: 100%;
    height: 100%;
  }

  /* #contentImage {
      width: auto;
      height: 100%;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      margin: auto 50%;
      transform: translate(-50%, 0);
  } */

  /* @keyframes fade-out {
      from { opacity: 1 }
      to { opacity: 0 }
  }
  @keyframes fade-in {
      from { opacity: 0 }
      to { opacity: 1 }
  }
  #contentImage.fade-out {
      opacity: 0;
      animation-name: fade-out;
  }
  #contentImage.fade-in {
      opacity: 1;
      animation-name: fade-in;
  } */

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
