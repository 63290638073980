<template>
  <div class="video-box">
    <video
      ref="videoElement"
      :src="hasSrcset ? null : contentUrl"
      preload="auto"
    >
      <template v-if="hasSrcset">
        <source
          v-for="(url, type) in contentSrcset"
          :key="type"
          :src="url"
          :type="type"
        />
      </template>
    </video>
    <div class="countdown">{{ secondsLeft }}</div>
    <qr-container
      v-if="qrPosition"
      :link="qrLink"
      :size="150"
      class="video-qr"
      :class="qrPosition"
    />
  </div>
</template>

<script>
import axios from 'axios'
import QrContainer from '../components/QrContainer'

export default {
  name: 'VideoBox',
  components: {
    QrContainer
  },
  data () {
    return {
      secondsLeft: null
    }
  },
  props: {
    contentUrl: {
      type: String,
      required: true
    },
    endUrl: {
      type: String,
      required: true
    },
    contentSrcset: {
      type: Object,
      default: null
    },
    qrPosition: {
      type: String,
      default: null
    },
    qrLink: {
      type: String,
      default: null
    }
  },
  computed: {
    hasSrcset () {
      return this.contentSrcset && Object.keys(this.contentSrcset).length > 0
    }
  },
  mounted () {
    try {
      this.$refs.videoElement.addEventListener('ended', () => {
        if (this.endUrl) {
          axios.get(this.endUrl)
        }
        this.$emit('finished')
      })

      this.$refs.videoElement.addEventListener('timeupdate', () => {
        if (this.$refs.videoElement) {
          this.secondsLeft = Math.round(this.$refs.videoElement.duration - this.$refs.videoElement.currentTime)
        }
      })

      this.$refs.videoElement.play().then(() => {
      }).catch((e) => {
        this.processError(e)
      })
    } catch (e) {
      this.processError(e)
    }
  },
  methods: {
    processError (e) {
      console.error('finishing because of error', e)
      this.$emit('finished')
    }
  }
}
</script>

<style lang="scss">
  .video-box {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    padding: 12px;

    video {
      width: 100%;
      max-height: 100%;
      padding: 0px;
      position: relative;
      z-index: 5;
    }
  }

  .countdown {
    background-color: rgb(182, 118, 230);
    border-radius: 18px;
    color: #fff;
    font-size: 1em;
    font-weight: 300;
    font-family: monospace;
    height: 32px;
    line-height: 32px;
    padding: 0px 7px;
    position: absolute;
    min-width: 32px;
    right: 10px;
    top: 10px;
    text-align: center;
    z-index: 10;
  }

  .video-qr {
    position: absolute;
    z-index: 10;

    &.rt {
      top: 30px;
      right: 30px;
    }

    &.rb {
      bottom: 30px;
      right: 30px;
    }

    &.lb {
      bottom: 30px;
      left: 30px;
    }

    &.lt {
      top: 30px;
      left: 30px;
    }
  }
</style>
