<template>
  <div
    v-if="qrData.link"
    class="donation-qr"
  >
    <qr-container
      :link="qrData.link"
      :size="qrWidth"
      class="donation-qr-inner"
    />
    <div class="text">{{ qrData.text }}</div>
  </div>
</template>

<script>
import axios from 'axios'
import layerMixin from '../../mixins/layer'
import QrContainer from '../../components/QrContainer'

export default {
  name: 'DonationQRPage',
  mixins: [layerMixin],
  components: {
    QrContainer
  },
  data () {
    return {
      pusher: null,
      channels: {},
      qrData: {},
      windowWidth: null
    }
  },
  mounted () {
    this.initPusher()

    this.enableWindowSizeTracking('donation-qr')

    this.loadInitialData()

    this.windowWidth = window.innerWidth
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  computed: {
    qrWidth () {
      return this.windowWidth * 1.5 / 1.7
    }
  },
  methods: {
    loadInitialData () {
      axios.get(this.backendHost + '/api/web-dashboard/v1/st/donations/layers/qr/' + this.userLink).then((res) => {
        this.qrData = res.data
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../../assets/fonts.scss';

  .donation-qr {
    font-size: (100*100/170)+vw;

    width: 1.7em;

    .qr-container {
      padding: 0.1em;
    }

    .text {
      font-family: 'GothamPro';
      font-weight: 500;
      text-align: center;

      font-size: 0.16em;
    }
  }
</style>