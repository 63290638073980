<template>
  <div class="qr-container">
    <qr-code
      :value="link"
      :size="size"
      level="H"
    />
  </div>
</template>

<script>
import QrCode from 'qrcode.vue'

export default {
  name: 'QrContainer',
  props: {
    link: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: true
    }
  },
  components: {
    QrCode
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
  .qr-container {
    background-color: white;
    padding: 10px;

    & > div {
      display: flex;
    }
  }
</style>