import Pusher from 'pusher-js'

const mixin = {
  data () {
    return {
      waitForLaunchInterval: null,
      isRuVersion: window.location.hostname === 'layer.apiqlive.ru',
      metaChannelPrefix: ''
    }
  },
  computed: {
    userLink () {
      return this.$route.params.userlink
    },
    metaChannelName () {
      return this.metaChannelPrefix + '.' + this.userLink
    },
    pusherHost () {
      if (!this.isRuVersion) {
        return process.env.VUE_APP_PUSHER_HOST
      }

      return process.env.VUE_APP_PUSHER_HOST.replace('apiq.live', 'apiqlive.ru')
    },
    backendHost () {
      if (!this.isRuVersion) {
        return process.env.VUE_APP_BACKEND_HOST
      }

      return process.env.VUE_APP_BACKEND_HOST.replace('apiq.live', 'apiqlive.ru')
    }
  },
  methods: {
    initPusher (metaChannelPrefix = 'presence-layer-meta') {
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        wssHost: this.pusherHost,
        wsHost: this.pusherHost,
        wssPath: process.env.VUE_APP_PUSHER_PATH,
        wsPath: process.env.VUE_APP_PUSHER_PATH,
        wssPort: process.env.VUE_APP_PUSHER_PORT,
        wsPort: process.env.VUE_APP_PUSHER_PORT,
        forceTLS: true,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        // activityTimeout: 45000,
        channelAuthorization: {
          endpoint: this.backendHost + '/pusher-presence-auth'
        }
      })

      this.metaChannelPrefix = metaChannelPrefix

      this.pusher.connection.bind('failed', () => {
        document.location.reload(true)
      })

      this.addEventsLogging()

      this.subscribeChannel('common-events')
        .bind('App\\Events\\Streamers\\AllLayersRefreshed', () => {
          document.location.reload(true)
        })
      
      this.subscribeChannel(this.metaChannelName)
        .bind('client-ping-is-alive', () => {
          this.channels[this.metaChannelName]
            .trigger('client-pong-is-alive', {
              userAgent: window.navigator.userAgent
              // ip ?
            })
        })
        .bind('App\\Events\\Streamers\\LayerRefreshed', () => {
          document.location.reload(true)
        })
    },
    enableWindowSizeTracking (layerName) {
      this.channels[this.metaChannelName]
        .bind('client-track-size-req-' + layerName, () => {
          this.channels[this.metaChannelName]
            .trigger('client-track-size-resp-' + layerName, {
              userAgent: window.navigator.userAgent,
              width: window.innerWidth,
              height: window.innerHeight
            })
        })
    },
    addEventsLogging () {
      this.pusher.connection.bind("error", function (error) {
        console.error('connection error', error)
      })

      this.pusher.connection.bind("connected", function () {
        console.warn('Connected event!')
      })

      this.pusher.connection.bind("disconnected", function () {
        console.warn('Disconnected event!')
      })

      this.pusher.connection.bind("state_change", function (states) {
        // states = {previous: 'oldState', current: 'newState'}
        console.warn('Channels current state is', states.current)
      })

      this.pusher.connection.bind("failed", function (error) {
        console.error('connection_failed', error)
      })

      window.addEventListener('online', () => {
        console.log('Window is online')
      })
      window.addEventListener('offline', () => {
        console.log('Window is offline')
      })
    },
    subscribeChannel (name) {
      this.channels[name] = this.pusher.subscribe(name)
      return this.channels[name]
    }
  }
}

export default mixin
